import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'next-i18next';
import Layout from 'components/Layout';
import FilterHeader from '@molecules/FilterHeader/FilterHeader';
import hasAuthToken from 'lib/hasAuthToken';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Link from 'next/link';
import SearchIcon from 'svg/search.svg';
import DoubleChevronRight from 'svg/double-chevron-right.svg';
import Button from 'components/Button';

const SearchTermItem = ({ href, label }) => (
  <li className="o-layout__cell o-layout__cell--fit">
    <Link href={href}>
      <a className="c-search-terms__link">
        <SearchIcon className="c-search-terms__icon" />
        {label}
      </a>
    </Link>
  </li>
);

const Homepage = () => {
  const { t } = useTranslation();
  const [numberOfVacancies, setNumberOfVacancies] = useState();
  const searchTerms = [
    {
      href: '/vacatures?query=Administratief+Medewerker',
      label: 'Administratief Medewerker',
    },
    { href: '/vacatures?query=Verkoopmedewerker', label: 'Verkoopmedewerker' },
    { href: '/vacatures?query=werkvoorbereider', label: 'Werkvoorbereider' },
    { href: '/vacatures?query=bedrijfsleider', label: 'Bedrijfsleider' },
    { href: '/vacatures?query=horeca', label: 'Horeca' },
    { href: '/vacatures?query=onderwijs', label: 'Onderwijs' },
    { href: '/vacatures?query=monteur', label: 'Monteur' },
    { href: '/vacatures?query=hr', label: 'HR' },
  ];

  useEffect(() => {
    axios(`${process.env.FRONTEND_ENDPOINT}/api/elastic/job-postings`).then(
      ({ data }) => setNumberOfVacancies(data?.totalResults)
    );
  }, []);

  return (
    <Layout
      title={t('homepage.siteTitle')}
      metaDescription={t('homepage.metaDescription')}
      metaKeywords={t('homepage.metaKeywords')}
      metaRobots={t('homepage.metaRobots')}
    >
      <FilterHeader
        numberOfVacancies={numberOfVacancies}
        autoCompleteLabel={t('homepage.autoCompleteLabel')}
        autoCompletePlaceholder={t('homepage.autoCompletePlaceholder')}
        placeLabel={t('homepage.placeLabel')}
        placePlaceholder={t('homepage.placePlaceholder')}
        submitLabel={t('homepage.submitLabel')}
        extraClasses="c-search-card--has-misc-button"
        withPlaceInput
        homepageFilter
      />
      <div className="o-retain o-retain--wall u-margin-top-base u-margin-bottom-base">
        <div className="c-search-terms">
          <h2>Populaire zoektermen</h2>
          <div className="o-retain o-retain--desk u-padding-right-none u-padding-left-none">
            <ul className="o-layout o-layout--gutter-tiny o-list-clean c-search-terms__list">
              {searchTerms.map(({ href, label }) => (
                <SearchTermItem key={href} href={href} label={label} />
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="o-retain o-retain--wall">
        <div className="o-layout o-layout--justify-center">
          <div className="o-layout__cell o-layout__cell--fit">
            <Button
              label={t('homepage.allJobPostingsCTA')}
              Tag="a"
              href="/vacatures"
              Icon={DoubleChevronRight}
              extraClasses="c-button--chevron"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export async function getServerSideProps({ req, res, locale }) {
  if (!hasAuthToken(req)) {
    res.setHeader(
      'Cache-Control',
      'public, max-age=300, s-maxage=300, stale-while-revalidate=600'
    );
  }

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}

export default Homepage;
